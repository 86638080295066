<template>
  <section class="m-comment-detail m-scroll">
    <!-- 头部导航 -->
    <m-header title="Detail">
      <!-- <span class="header-slot" slot="right" @click="submitCommentHandle">提交</span> -->
    </m-header>

    <div class="readonly">
      <div class="rate">
        <div class="time">{{comments.created_at}}</div>
        <van-rate  v-model="comments.s_comment" readonly :size="18" color="#F03C18" void-icon="star" void-color="#ccc"/>
      </div>
      <p class="comment-text">{{comments.content? comments.content: 'Evaluation party fails to make timely evaluation, the system default praise'}}</p>
      <div class="comment-pic">
        <div class="comment-video" v-if="comments.video">
            <img class="cover" :src="comments.video.video_pic_url"  alt="">
            <img src="@/assets/goods/play.png" alt="" class="play-video" @click="playVideo(comments.video.remote_url)">
        </div>
        <div class="pic" v-if="comments.pic != null">
          <img :src="item.remote_url" alt="" v-for="(item, id) in comments.pic" :key="id" @click="viewImg(comments.pic, id)">
        </div>
      </div>
    </div>
    <div class="mooby-reply" v-if="comments.call_waiter_reply">
      <div class="kailong"></div>
      Merchant reply：{{comments.call_waiter_reply.content}}
    </div>
    <div class="comment-item">
      <div class="item-content">
        <div class="content-left"><img :src="comments.order_goods.goods_sku_pic_url" alt="goods"></div>
        <div class="content-right">
          <p class="goods-title van-multi-ellipsis--l2">{{comments.order_goods.goods_ename}}</p>
          <span>{{comments.order_goods.sku_ename}}</span>
        </div>
      </div>
    </div>
    <van-popup v-model="showVideo" position="left" :style="{width:'100%',height: '100%', background: '#000'}" closeable close-icon="close" @closed="onPlayerPause(player)">
        <video-player
          class="video-player vjs-custom-skin video"
          ref="videoPlayer"
          autoplay
          playsinline
          x5-playsinline
          webkit-playsinline
          :mtt-playsinline="true"
          :x5-video-player-type="h5" 
          x5-video-player-fullscreen
          :options="playerOptions"
          @pause="onPlayerPause($event)"
        ></video-player>
      </van-popup>
    <!-- <div class="description">
        <img src="@/assets/comment/icon_bianji@2x.png" alt="" class="icon-edit">
        <textarea v-model="goods.content" placeholder="从多个角度评价宝贝，可以帮助更多想买的人～"></textarea>

        <div class="upload-wrap">
        <van-uploader v-model="goods.file_list" :after-read="(file) => uploadImg(file, index)" :upload-text="imgText(index)"
        :upload-icon="require('@/assets/comment/icon_tupian@2x.png')" :before-delete="(file) => delPicHandle(file, index)"
        max-count="5" preview-size="69px"></van-uploader>
        </div>
    </div> -->
  </section>
</template>

<script>
import { ImagePreview } from 'vant'
import 'video.js/dist/video-js.css'
import 'vue-video-player/src/custom-theme.css'
import MHeader  from '@/components/zh/m-header.vue'
import { submitCommen, comment_detail } from '@/api/zh/order.js'
import { upload, delpic } from '@/api/public.js'
export default {
  name:'Index',
  components:{ MHeader },
  data(){
    return {
      w_rate:5,
      f_rate: 5,
      page: 1,
      goodsList: [],
      goods: {},
      h5:'h5-page',
      playerOptions : {
        autoplay: false, //如果true,浏览器准备好时开始回放。
        muted: false, // 默认情况下将会消除任何音频。
        loop: false, // 导致视频一结束就重新开始。
        preload: 'auto', // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
        aspectRatio: '375:375', // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
        fluid: false, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
        poster: '', //你的封面地址
        sources: [{
          type: "video/mp4",//这里的种类支持很多种：基本视频格式、直播、流媒体等，具体可以参看git网址项目
          src: '' //url地址
        }],
        width: document.documentElement.clientWidth, //播放器宽度
        notSupportedMessage: '视频加载失败', //允许覆盖Video.js无法播放媒体源时显示的默认信息。
        controlBar: {
          timeDivider: true, // 当前时间和持续时间的分隔符
          durationDisplay: true, // 显示持续时间
          remainingTimeDisplay: false, // 是否显示剩余时间功能
          fullscreenToggle: false, // 是否显示全屏按钮
          volumePanel: false, // 是否显示声音按钮
        },
      },
      showVideo: false,
      comments: {call_waiter_reply:{call_waiter:{}},order_goods:{},video:{}},
      player: ''
    }
  },
  mounted(){
    this.getData()
  },
  methods:{
    getData() {
      comment_detail(this.$route.query.id).then(res => {
        if(res) {
          this.comments = res.data
        }
      })
    },
    playVideo(src) {
      this.playerOptions.sources[0].src = src
      this.showVideo = true
      this.$nextTick(() => {
        this.$refs.videoPlayer.player.play()
        this.player = this.$refs.videoPlayer.player
      })
    },
    // 暂停播放
    onPlayerPause(player) {
      player.pause()
    },
    viewImg(list, index) {
      let imgList = []
      list.forEach(el => {
        imgList.push(el.remote_url)
      })
      // 预览图片
      ImagePreview({
        images: imgList,
        startPosition: index - 0
      })
    },
    // 上传视频
    uploadVideo(file) {
      console.log(file)
      // this.goodsList[index].video_id = ''
      // this.goodsList[index].poster = ''
    },
    // 点击视频预览图删除按钮
    videoDelete(){
      this.video = {
        id: '',
        poster: ''
      }
    },
    uploadImg(file, index) {
      // 上传图片
      file.status = 'uploading'
      file.message = '上传中...'

      var image = new FormData()
      image.append('filename', 'comment')
      image.append('file', file.file)
      this.load = true
      upload(image).then(res => {
        if(res){
          this.goodsList[index].pic_list.push(res.data.pic_id)
          file.pic_id = res.data.pic_id
        }
      }).finally(() => {
        file.status = 'done'
        file.message = ''
      })
    },
    delPicHandle(file, index) {
      if(file.pic_id != "") {
        
        delpic(file.pic_id).then( res => {
          if(res) {
            // 删除成功
            let i = this.goodsList[index].file_list.findIndex(v => v.pic_id == file.pic_id)
            this.goodsList[index].file_list.splice(i, 1)
            this.goodsList[index].pic_list.splice(i, 1)
          }
        })
      }
    },
    submitCommentHandle() {
      // 提交评价
      let params = []

      this.goodsList.forEach(el => {
        params.push({
          goods_id:  el.goods_id,
          order_id:  this.$route.query.id,
          content:   el.content,
          video_id:  el.video_id,
          pic_id:    el.pic_list.join(','),
          s_comment: el.rate,
          w_comment: this.w_rate,
          f_comment: this.f_rate
        })
      })
      console.log(params)
      submitCommen(params).then(res => {
        if(res) {
          this.$notify({ type: 'success', message: '提交评论成功' })
          this.$router.go(-1)
        } else {
          this.$notify({ type: 'warning', message: '提交评论失败，请稍后再试' })
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.m-comment-detail {
  width: 100%;
  height: 100%;
  padding-top:44px;
  overflow-x: hidden;
  overflow-y: auto;
  .readonly {
    width: 100%;
    background-color: #fff;
    padding: 5px 16px 0;
    border-top: 5px solid #F5F9FF;
    .rate {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 5px;
      .time {
        color: #ccc;
        font-size: 13px;
      }
    }
    .comment-text {
      width: 100%;
      margin-bottom: 20px;
    }
    .comment-pic {
      width: 100%;
      .comment-video {
        width: 100%;
        height: 343px;
        position: relative;
        margin-bottom: 15px;
        .cover {
          width: 100%;
          height: 100%;
        }
        .play-video {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 50vw;
        }
      }
      .pic {
        width: 100%;
        margin-bottom: 10px;
        img {
          width: 100%;
        }
      }
    }
  }
  .comment-item {
    background: #fff;
    margin-top: 10px;
    padding: 0 16px 20px;
    .item-content {
      border-top: 1px solid #f5f5f5;
      background: #f8f8f8;
      height: 102px;
      display: flex;
      align-items: center;
      .content-left {
        width: 80px;
        height: 80px;
        margin-right: 12px;
        margin-left: 12px;
        border-radius: 5px;
        &>img {
          width: 80px;
          height: 80px;
        }
      }
      .content-right {
        flex: 1;
        height: 80px;
        padding: 8px 0;
        .goods-title{
          height: 40px;
          font-size:16px;
          font-weight:400;
          color:rgba(51,51,51,1);
          line-height: 20px;
          margin-bottom: 10px;
        }
        >span {
          display: inline-block;
          line-height: 20px;
          font-size: 12px;
          font-weight: 400;
          color: #999;
        }
      }
    }
  }
  .mooby-reply {
    margin: 30px 16px 20px;
    background: #f7f7f7;
    color: #444;
    padding: 8px 8px;
    font-size: 14px;
    position: relative;
    border-radius: 5px;
    .kailong{
      position: absolute;
      top: -17px;
      left: 21px;
      border-right:18px solid transparent;
      border-left:18px solid transparent;
      border-bottom:18px solid #f7f7f7;
    }
  }
  /deep/.video-player{position:absolute;left:0;top:50%;transform: translateY(-50%);width:100%;
    .video-js .vjs-tech{height:auto !important;position: relative !important;}
    .vjs-fluid{padding:0 !important}
    .video-js.vjs-fluid, .video-js.vjs-16-9, .video-js.vjs-4-3{height:fit-content}
  }
  .store-reply{margin-top:10px;font-size: 14px;background-color: #f7f7f7;padding:7px 10px;border-radius: 6px;color:#444;line-height: 22px;}
}
</style>